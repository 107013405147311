<div *ngIf="tienda_seleccionada >= 0; then thenBlock else elseBlock"></div>


<ng-template #thenBlock>
    <app-breadcrumb [title]="'Contact Center'" [items]="['Parque', 'Registros']"
        [active_item]="nombre_tienda_seleccionada">
    </app-breadcrumb>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="container contenedor-leads">
                        <div class="row detalle-leads">
                            <h5 class="m-t-5" *ngIf="user_type_id == 3">Parque : {{campus_name}}</h5>
                            <div class="row resumen-leads">
                          
                                <div class="col-6 col-sm-6 col-xl-6 col-lg-6">
                                    <div class="card o-hidden">
                                        <div class="bg-pendiente b-r-4 card-body">
                                            <div class="media static-top-widget">
                                                <div class="align-self-center text-center">
                                                    <app-feather-icons [icon]="'loader'"></app-feather-icons>
                                                </div>
                                                <div class="media-body"><span
                                                        class="nombre-estado-cliente m-0">Pendiente</span>
                                                    <h4 class="number-estado-cliente mb-0 counter"
                                                        CountTo="{{detallado_leads.pendientes}}" from="0" duration="1">
                                                        {{detallado_leads.pendientes}}</h4>
                                                    <i class="icon-bg" data-feather="loader"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6 col-xl-6 col-lg-6">
                                    <div class="card o-hidden">
                                        <div class="bg-venta b-r-4 card-body">
                                            <div class="media static-top-widget">
                                                <div class="align-self-center text-center">
                                                    <app-feather-icons [icon]="'dollar-sign'"></app-feather-icons>
                                                </div>
                                                <div class="media-body"><span
                                                        class="nombre-estado-cliente m-0">Resueltos</span>
                                                    <h4 class="number-estado-cliente mb-0 counter"
                                                        CountTo="{{detallado_leads.ventas}}" from="0" duration="1">
                                                        {{detallado_leads.resueltos}}</h4>
                                                    <i class="icon-bg" data-feather="dollar-sign"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                    
                                
                                <div class="col-12">
                                    <div class="card o-hidden">
                                        <div class="bg-total b-r-4 card-body">
                                            <div class="media static-top-widget">
                                                <div class="align-self-center text-center">
                                                    <app-feather-icons [icon]="'users'"></app-feather-icons>
                                                </div>
                                                <div class="media-body"><span
                                                        class="nombre-estado-cliente m-0">Total</span>
                                                    <h4 class="number-estado-cliente mb-0 counter"
                                                        CountTo="{{detallado_leads.total}}" from="0" duration="1">
                                                        {{detallado_leads.total}}</h4>
                                                    <i class="icon-bg" data-feather="users"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="card-body card-body-filtros">
                                    <div class="buttons">
                                        <button type="button" class="btn btn-primary-honda button_filter__collapse"
                                            (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
                                            aria-controls="collapseExample">
                                            <span>Filtros</span>
                                            <app-feather-icons [icon]="'filter'"></app-feather-icons>
                                        </button>
                                        
                                          <!-- 
                                   <button *ngIf="user_type_id != 2" type="button"
                                            class="btn btn-venta button_filter__collapse" (click)="exportar()"
                                            aria-controls="collapseExample">
                                            <span>Exportar</span>
                                            <app-feather-icons [icon]="'file-text'"></app-feather-icons>
                                        </button>

                                        -->
                                        
                                      
                                   
                                    </div>
                                    <div id="collapseExample" [ngbCollapse]="isCollapsed">
                                        <div class="card mb-0">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <h5><u>Filtros</u></h5>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">Fecha de
                                                                inicio</label>
                                                            <div class="col-sm-9">
                                                                <input class="form-control digits"
                                                                    [(ngModel)]="filtros.fecha_inicio"
                                                                    id="example-datetime-local-input" type="date">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">Fecha de fin</label>
                                                            <div class="col-sm-9">
                                                                <input class="form-control digits"
                                                                    [(ngModel)]="filtros.fecha_fin"
                                                                    id="example-datetime-local-input" type="date">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label class="col-form-label">Motivo Solicitud</label>
                                                            <ng-select [items]="default_values.motivo_solicitud"
                                                                    class="js-example-basic-multiple"
                                                                    placeholder="Selecciona prioridad"
                                                                    [(ngModel)]="filtros.motivo_solicitud"
                                                                    [multiple]="true">
                                                            </ng-select>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label class="col-form-label">Derivación</label>
                                                            <ng-select [items]="default_values.derivar"
                                                                    class="js-example-basic-multiple"
                                                                    placeholder="Selecciona prioridad"
                                                                    [(ngModel)]="filtros.derivar"
                                                                    [multiple]="true">
                                                            </ng-select>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label class="col-form-label">Estado del Caso</label>
                                                            <ng-select [items]="default_values.estado_caso"
                                                                    class="js-example-basic-multiple"
                                                                    placeholder="Selecciona estado"
                                                                    [(ngModel)]="filtros.estado_caso"
                                                                    [multiple]="true">
                                                            </ng-select>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label class="col-form-label">Prioridades</label>
                                                            <ng-select [items]="default_values.prioridad_atencion"
                                                                    class="js-example-basic-multiple"
                                                                    placeholder="Selecciona prioridad"
                                                                    [(ngModel)]="filtros.prioridad_atencion"
                                                                    [multiple]="true">
                                                            </ng-select>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <div class="col-form-label">Tipo de filtro</div>
                                                            <ng-select [items]="default_values.tipo_filtro"
                                                                class="js-example-basic-multiple" placeholder=""
                                                                [(ngModel)]="filtros.tipo_filtro" [multiple]="false">
                                                            </ng-select>
                                                        </div>
                                                    </div>

                                            
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <div class="form-group">
                                                                <div class="col-form-label">Por texto</div>
                                                                <input class="form-control" type="text"
                                                                    [(ngModel)]="filtros.texto" placeholder="">
                                                            </div>
                                                        </div>
                                                    </div>
                                           

    
                                            
                                                    <div class="col-12 col-md-12 div-button_filter">
                                                        <button id="make_filter" type="button" class="btn btn-primary"
                                                            (click)="rerender()" [attr.aria-expanded]="!isCollapsed"
                                                            aria-controls="collapseExample"
                                                            [attr.disabled]="buttonFiltrarTexto != 'Filtrar' ? true:null">
                                                            <span>{{buttonFiltrarTexto}}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="card-body custom-datatable noscroll table-card-container">
                                    <div class="custom-datatable table-responsive">
                                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                            class="row-border hover table table-leads pulpa-datatable-responsive nowrap display">
                                            <thead>
                                                <tr>
                                                    <th *ngFor="let cabecera of tableCabeceras">{{cabecera.name}}</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="persons?.length != 0">
                                                <tr *ngFor="let caso of persons" class="tr-mobile">
                                                    <div class="imagen-container">
                                                        <img class="imagen-moto" src="{{caso.moto_imagen}}" alt="Imagen de {{caso.auto_modelo}}">
                                                    </div>
                                                    <th *ngFor="let cabecera of tableCabeceras">
                                                        <div class="descripcion">{{cabecera.name}}</div>
                                                        <container-element [ngSwitch]="cabecera.id">
                                                            <some-element class="mobile-detail" *ngSwitchCase="1">
                                                                {{caso.id}}
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="2">
                                                                {{caso.nombres}} {{caso.apellidos}} <br> 
                                                                {{caso.doc_type}}: {{caso.dni}}
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="3">
                                                                {{caso.prioridad_atencion}} <br> 
                                                                {{caso.motivo_solicitud}}  <br/>
                                                                ({{caso.tipo_caso}}
                                                                <ng-container *ngIf="caso.detalle">
                                                                    - {{caso.detalle}})
                                                                </ng-container>
                                                                <ng-container *ngIf="!caso.detalle">
                                                                    )
                                                                </ng-container>
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="4">
                                                                {{caso.created_time}}
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="5">

                                                                <span class="badge rounded-pill {{caso.bg_class}}">{{caso.estado_caso | uppercase}}</span>
                                                          
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="6">
                                                                {{caso.unidad}} <br/>
                                                                <ng-container *ngIf="caso.campus_name">
                                                                    ({{caso.campus_name}})
                                                                </ng-container>
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="7">
                                                                {{caso.derivar}}: <br/>
                                                                <ng-container *ngIf="caso.seller">
                                                                    <!-- Descomenta el código original -->
                                                                    <ng-container *ngFor="let seller of (parseJSON(caso.seller))">
                                                                        {{seller.nombres}}
                                                                        <br>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </some-element>
                                                            
                                                            <some-element class="mobile-detail" *ngSwitchCase="8">
                                                         
                                                                <ng-container *ngFor="let seguimiento of (parseJSON(caso.seguimiento_caso))">
                                                                    {{seguimiento.nombres}}
                                                                    <br>
                                                                </ng-container>
                                                          
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="9">
                                                                <a [routerLink]="['/contact-center/caso', caso.id]">
                                                                    <button [attr.data-registro]="caso.id" data-toggle="modal" data-target="#modal_details" class="btn btn-success button_see_details">
                                                                        Ver detalle
                                                                    </button>
                                                                </a>
                                                            </some-element>
                                                            
                                                        </container-element>
                                                    </th>
                                                </tr>
                                                
                                           
                                            </tbody>
                                            <tbody *ngIf="persons?.length == 0">
                                                <tr>
                                                    <td colspan="3" class="no-data-available">{{message_to_show_table}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>

<ng-template #elseBlock>
    <app-breadcrumb [title]="''" [items]="['Registros', 'Leads Comerciales']"
        [active_item]="'Seleccione Parque'">
    </app-breadcrumb>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>¡Seleccione Parque!</h5><span>Debe elegir un concesionario para ver los leads.</span>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-concesionarios">
                            <thead>
                                <tr>
                                 
                                    <th scope="col">Nombre del Parque</th>
                                    <th scope="col">Ir</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let tienda of tiendas">
                               
                                    <td>{{tienda.name}}</td>
                                    <td>
                                        <a [routerLink]="['/contact-center/tienda', tienda.campus_id]"><i class="fa fa-arrow-circle-right"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>