<app-breadcrumb [title]="'Detalle Lead'" [items]="['Registros']" [active_item]="'Leads Comerciales'"></app-breadcrumb>
<div class="go-back">
    <a (click)="go_back()"><i class="fa fa-arrow-circle-left"></i></a>
</div>
<div class="container-fluid">
    <div class="user-profile">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-xl-7 col-lg-12">
                <div class="card ribbon-wrapper hovercard text-center">
                    <div class="ribbon ribbon-primary {{lead.bg_class}}">{{lead.estado_caso}}</div>
                    <div *ngIf="lead.moto_imagen">
                        <div class="cardheader"
                            style="background: url('{{lead.moto_imagen}}');background-size:contain;background-repeat:no-repeat;background-position: center;">
                        </div>
                    </div>
                    <div class="info">
                        <div class="row">

                              
                            <div class="col-sm-12 col-lg-12 order-sm-0 order-lg-0 order-xl-1">
                                <div class="user-designation">
                                    <p>{{ lead.unidad }}</p>
                                    <div class="title"><a>{{lead.nombres}}</a>
                                    </div>
                                    <span>#{{ lead.id }}</span>
                                </div>
                            </div>

                            <div class="col-sm-12 col-lg-12 order-sm-1 order-lg-1 order-xl-2">

                                <div class="col-md-12 m-t-20 m-b-10">
                                    <div class="ttl-info text-center lead-info">
                                        <div class="title" > {{lead.motivo_solicitud}} </div>
                                    </div>
                                </div>
                                
                                <div class="row text-center mt-3">
                                    <div class="col-12 col-md-6">
                                        <div class="title" >Tipo de Caso</div>
                                        <p><i style="color:#541879;font-weight:bold" class="fa fa-file-text" aria-hidden="true"></i></p>
                                        <p>{{ lead.motivo_solicitud }} ({{ lead.tipo_caso }} - {{ lead.detalle }})</p>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="title" >Prioridad del Caso</div>
                                        <p><i style="color:#541879;font-weight:bold" class="fa fa-exclamation-triangle" aria-hidden="true"></i></p>
                                        <p>{{ lead.prioridad_atencion }}</p>
                                    </div>
                                </div>
                                
                                <div class="row text-center mt-3">
                                    <div class="col-12 col-md-6">
                                        <div class="title" >Nombres</div>
                                        <p><i style="color:#541879;font-weight:bold" class="fa fa-id-card-o" aria-hidden="true"></i></p>
                                        <p>{{ lead.nombres }} {{ lead.apellidos }}</p>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="title" >Correo Electrónico</div>
                                        <p><i style="color:#541879;font-weight:bold" class="fa fa-envelope-o" aria-hidden="true"></i></p>
                                        <p>{{ lead.correo_electronico }}</p>
                                    </div>
                                </div>
                                
                                <div class="row text-center mt-3">
                                    <div class="col-12 col-md-6">
                                        <div class="title" >Teléfono</div>
                                        <p><i style="color:#541879;font-weight:bold" class="fa fa-phone" aria-hidden="true"></i></p>
                                        <p>{{ lead.celular }}</p>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="title" >Fecha de creación</div>
                                        <p><i style="color:#541879;font-weight:bold" class="fa fa-calendar" aria-hidden="true"></i></p>
                                        <p>{{ lead.created_time }}</p>
                                    </div>
                                </div>
                                
                                
                            </div>

                            <div class="col-sm-12 col-lg-12 order-sm-0 order-lg-0 order-xl-3">

                                <div class="row text-center mt-3" id="escalamiento_unidad_edit">
                                  
                                  <div class="row text-center mt-3" id="origen_caso">

                                    <div class="col-12 col-md-6">
                                     <div class="title" >Origen Caso</div>
                                      <p><i class="fa fa-volume-control-phone" aria-hidden="true" style="color:#541879;font-weight:bold"></i></p>
                                      <p id="origen__caso">{{ lead.origen_caso }}</p>
                                    </div>

                                    <div class="col-12 col-md-6" *if="lead.entero_honda">
                                     <div class="title" >¿Cómo se enteró de la Marca?</div>
                                      <p><i class="fa fa-commenting-o" aria-hidden="true" style="color:#541879;font-weight:bold"></i></p>
                                      <p id="entero_honda">{{ lead.entero_honda }}</p>
                                    </div>
                                  </div>

                                 
                                 </div>
                            </div>


                            <div class="col-sm-12 col-lg-12 order-sm-0 order-lg-0 order-xl-4">
                                <div *ngIf="caso_vinculado.length != 0">
                                    <div class="row text-center mt-3">
                                        <div class="col-12 col-md-12">
                                            <p><b>Caso Vinculado</b></p>
                                            <input
                                                style="text-align:center"
                                                [value]="lead.caso_vinculado_id"
                                                class="form-control"
                                                id="registro___detail_caso_vinculado"
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                    </div>
                            
                                    <div class="row text-center mt-3">
                                        <div class="col-12 col-md-12">
                                            <div class="accordion" id="accordionExample">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <button
                                                            class="accordion-button"
                                                            type="button"
                                                            (click)="toggleAccordion()"
                                                            [ngClass]="{'collapsed': !abierto_caso_vinculado}"
                                                            aria-expanded="{{ abierto_caso_vinculado }}"
                                                            aria-controls="collapseOne"
                                                        >

                                                            <b>
                                                                Detalle de caso vinculado #{{ caso_vinculado.id }}
                                                            </b>

                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapseOne"
                                                        class="accordion-collapse"
                                                        [ngClass]="{'collapse show': abierto_caso_vinculado, 'collapse': !abierto_caso_vinculado}"
                                                        aria-labelledby="headingOne"
                                                    >
                                                        <div class="accordion-body">
                                                            <table class="table">
                                                                <tr>
                                                                    <td><b>Fecha de creación</b></td>
                                                                    <td>{{ caso_vinculado.created_time }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Motivo Solicitud</b></td>
                                                                    <td>{{ caso_vinculado.motivo_solicitud }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Tipo Caso</b></td>
                                                                    <td>{{ caso_vinculado.tipo_caso }} : {{ caso_vinculado.detalle }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Marca</b></td>
                                                                    <td>{{ caso_vinculado.unidad }} - {{ caso_vinculado.campus_name }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Estado</b></td>
                                                                    <td>{{ caso_vinculado.estado_caso }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Detalle</b></td>
                                                                    <td>{{ caso_vinculado.detalle }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Prioridad</b></td>
                                                                    <td>{{ caso_vinculado.prioridad_atencion }}</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-lg-12 order-sm-0 order-lg-0 order-xl-5 ">
                                <div class="dropdown mt-5 ">
                                  <button class="btn btn-primary" type="button" (click)="toggleHistorial()">
                                    {{ abrir_historial ? 'Ocultar Historial' : 'Ver Historial' }}
                                  </button>
                                </div>
                              
                                <div *ngIf="abrir_historial" class="mt-3">
                                  <div class="card card-body">
                                    <table class="table">
                                      <thead>
                                        <tr>
                                          <th>Fecha de Historial</th>
                                          <th>Acción</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let item of historial">
                                          <td>{{ item.fecha }}</td>
                                          <td>{{ item.accion }}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                        </div>
                  
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-xl-5 col-lg-12 box-col-5">
                <div class="card info-right">
                    <div class="card-header py-4">
                        <p>Información</p>
                    </div>
                    <div class="card-body">
                        <div class="theme-form row">
                            <div class="formulario-edit">

                                  <div class="row" id="escalamiento_unidad_edit">
                                    <div class="col-12 col-md-12">
                                      <div class="form-group">
                                        <label for="modelo">Servicio:</label>
                                        <input class="form-control" id="modelo" type="text" [value]="lead.modelo || 'No disponible'" disabled>
                                      </div>
                                    </div>
                        
                                  </div>
                                  
                                  <div class="row">
                                    <div class="col-12 col-md-12">
                                      <div class="form-group">
                                        <label for="derivar">Derivación:</label>
                                        <input class="form-control" id="derivar" type="text" [value]="lead.derivar || 'No disponible'" disabled>
                                      </div>
                                      <ng-container *ngFor="let seller of getSellerEmails()">
                                        <input class="form-control mt-2" type="text" [value]="seller.email" disabled>
                                      </ng-container>
                                    </div>
                                  
                                    <div *ngIf="lead.derivar !== 'DERIVACIÓN SOLO A GERENCIA' &&  getSeguimientoCaso().length != 0 " class="col-12 col-md-12">
                                      <div class="form-group">
                                        <label for="seguimiento_caso">Seguimiento de Caso:</label>
                                        <ng-container *ngFor="let seguimiento of getSeguimientoCaso()">
                                          <input class="form-control mt-2" type="text" [value]="seguimiento.nombres + ' - ' + seguimiento.email" disabled>
                                        </ng-container>
                                      </div>
                                    </div>
                                  
                                    <div *ngIf="lead.derivar !== 'DERIVACIÓN SOLO A GERENCIA' && getSupervisoresTienda().length != 0" class="col-12 col-md-12">
                                      <div class="form-group">
                                        <label for="supervisores_tienda">Supervisores Tienda:</label>
                                        <ng-container *ngFor="let supervisor of getSupervisoresTienda()">
                                          <input class="form-control mt-2" type="text" [value]="supervisor.nombres + ' - ' + supervisor.email" disabled>
                                        </ng-container>
                                      </div>
                                    </div>
                                  </div>
                                  

                                <div class="form-group col-12" *ngIf="lead.fecha_cita">
                                    <label for="">Fecha de Cita: </label>
                                    <input class="form-control" type="text" placeholder="{{lead.fecha_cita}}"
                                        disabled>
                                </div>

                                <div class="form-group col-12 mt-3">
                                    <label for="">Estado del Caso</label>
                                    <ng-select [items]="payloadForm.estado_caso"
                                        [(ngModel)]="lead_to_update.estado_caso" class="js-example-basic-multiple"
                                        (ngModelChange)="handleEstadoClienteChange($event)" >
                                    </ng-select>
                                </div>
                                

                                <div class="form-group" *ngIf="user_type_id != 3 && user_type_id != 5">
                                    <label for="primera_apertura"><u>Tiempo de primera apertura:</u></label>
                                    
                                    <p>{{tiempos.primera_apertura ? tiempos.primera_apertura : '-' }}</p>
                                </div>
                                <div class="form-group" *ngIf="user_type_id != 3 && user_type_id != 5">
                                    <label for="primera_atencion"><u>Tiempo de primera atención:</u></label>
                          
                                    <p>{{tiempos.primera_atencion ? tiempos.primera_atencion : '-'}}</p>
                                </div>
                                <div class="form-group" *ngIf="user_type_id != 3 && user_type_id != 5">
                                    <label for="ultimo_click"><u>Último click:</u></label>
                              
                                    <p>{{tiempos.ultimo_click ? tiempos.ultimo_click : '-'}}</p>
                                </div>
                                <div class="form-group" *ngIf="user_type_id != 3 && user_type_id != 5">
                                    <label for="last_edit"><u>Última edición:</u></label>
                    
                                    <p>{{tiempos.last_edit ? tiempos.last_edit : '-'}}</p>
                                </div>
                                
                         

                                <div class="form-group col-12">
                                    <label for="comentarios">Comentario Cliente</label>
                                    <textarea [disabled]="user_type_id != 2" [(ngModel)]="lead_to_update.comentarios"
                                        class="form-control" name="" id="comentarios_cliente" cols="30" rows="5" disabled ></textarea>
                                </div>

                                <div class="form-group col-12" >
                                    <label for="notas">Crear Nueva Nota <i class="fa fa-plus-circle" aria-hidden="true"></i></label>
                                    <textarea class="form-control" name="" id="comentarios" cols="30" rows="5" [(ngModel)]="nueva_nota"></textarea>
                                </div>

                                <div class="form-group row col-12 m-b-15" >
                                    <div class="col-6">

                                        <button class="form-group btn btn-success col-12" (click)="guardarNota()" [disabled]="!nueva_nota.trim()">
                                            <i class="icofont icofont-comment"></i> Guardar Nota
                                        </button>
                                    </div>

                                    <div class="col-6">
                                        <button class="btn btn-danger col-12" (click)="actualizar()">
                                          Guardar Cambios
                                        </button>
                                    </div>
                           
                                </div>

                                <div class="form-group row col-12 m-b-15"  >
                                  

                                    <div class="col-12 mb-2" *ngIf="user_type_id == 2">
                                        <button class="btn btn-success col-12" (click)="programar_llamada()" >Ver mi
                                            calendario <i class="icofont icofont-ui-calendar"></i>
                                        </button>
                                    </div>

                                    <div  class="col-12"   >
                                        <button class="form-group btn btn-info col-12 " (click)="toggleCollapse()"> <i class="icofont icofont-speech-comments"></i> Mostrar/Ocultar Notas</button>
                                    </div>

                                    
                                </div>

                                <div id="comentariosCollapse" class="m-b-15" *ngIf="comentarios_lead && comentarios_lead.length > 0">

                                    <div *ngIf="!isCollapsed" >Comentarios:</div>
                                    
                                    <div class="card card-body" [ngClass]="{'collapse': isCollapsed}" *ngFor="let comentario of comentarios_lead">
                                        <div [ngClass]="'border rounded p-3'">
                                            <p class="mb-2" style="font-size: 11px;">{{comentario.accion}}</p>
                                            <p class="mb-2" style="font-size: 14px;">{{comentario.comentario}}</p>
                                            <p class="mb-0" style="font-size: 10px;">{{comentario.created_time}}</p>
                                        </div>
                                    </div>
                                    
                                </div>

                   


                            </div>

                   
                        
                                
                            
                            <!-- <div class="col-12">
                                <button class="btn btn-primary d-block w-100" type="button" title="">Submit</button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>