import { Component, NgModule, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from "@angular/common"
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { SendCotizacionComponent } from '../modales-generados/send-cotizacion/send-cotizacion.component';
import { LeadHistoryInfoComponent } from '../modales-generados/lead-history-info/lead-history-info.component';
import * as moment from 'moment';
import adapter from 'webrtc-adapter';
import { ModalReasignarLeadComponent } from '../../modal-reasignar-lead/modal-reasignar-lead.component';
import { ModalSeeUtmsHistoryComponent } from '../../modal-see-utms-history/modal-see-utms-history.component';
import { ModalReprogramadoLeadComponent } from '../../modal-reprogramado-lead/modal-reprogramado-lead.component';

import { ModalProgramarLlamadaComponent } from '../../modal-programar-llamada/modal-programar-llamada.component';
const Swal = require('sweetalert2');

import {
  ButtonsConfig,
  ButtonsStrategy,
  // GalleryService,
  Image,
  KS_DEFAULT_BTN_CLOSE,
  KS_DEFAULT_BTN_DOWNLOAD,
  KS_DEFAULT_BTN_EXTURL,
  KS_DEFAULT_BTN_FULL_SCREEN,
  ButtonEvent,
  ButtonType,
  PlainGalleryConfig,
  PlainGalleryStrategy,
  ModalGalleryService,
  PlainLibConfig,
  LineLayout,
  ModalGalleryRef
} from '@ks89/angular-modal-gallery';


@Component({
  selector: 'app-caso-detalle',
  templateUrl: './caso-detalle.component.html',
  styleUrls: ['./caso-detalle.component.scss']
})


export class CasoDetalleComponent implements OnInit  {

  lead_to_update


  private routeSub: Subscription;

  protected tienda_id
  protected caso_id
  protected lead
  protected telefono
  protected call_again

  datos_llamada = {
    'inicio': 0,
    'fin': 0
  }

  tiempos = {
    primera_atencion: '',
    ultimo_click: '',
    primera_apertura: '',
    last_edit: ''
  }

  sendMail: Boolean = false

  payloadForm

  nueva_nota: string = ''; 
  comentarios_lead: any

  respuesta_generica
  encargado_lead

  user_type_id

  texto_button_call
  on_call

  public url: any;

  private jwt

  images: Image[] = [
    new Image(
      0,
      {
        img: 'assets/images/other-images/profile-style-img.png',
        extUrl: 'http://www.google.com'
      })
  ]
  images1: Image[] = [
    new Image(
      0,
      {
        img: 'assets/images/blog/img.png',
        extUrl: 'http://www.google.com'
      })
  ]
  constructor(
    private route: ActivatedRoute,
    private modalGalleryService: ModalGalleryService,
    public router: Router,
    private http: HttpClient,
    private modalService: NgbModal,
    private location: Location
  ) {
    this.lead = {
      moto_imagen: '',
      disponible_mail: false
    }

    this.jwt = JSON.parse(localStorage.user).jwt

    this.payloadForm = {}

    this.lead_to_update = {}


    this.call_again = ''

    this.telefono = ''

    this.user_type_id = JSON.parse(localStorage.user).user_type_id

    this.texto_button_call = `Llamar <br><i class="icofont icofont-ui-call"></i>`

    this.on_call = false
  }


  libConfigPlainGalleryRow: PlainLibConfig = {
    plainGalleryConfig: {
      strategy: PlainGalleryStrategy.ROW,
      layout: new LineLayout({ width: '80px', height: '80px' }, { length: 2, wrap: true }, 'flex-start')
    }
  };

  onShow(id: number, index: number, images: Image[] = this.images): void {
    const dialogRef: ModalGalleryRef = this.modalGalleryService.open({
      id,
      images,
      currentImage: images[index]
    }) as ModalGalleryRef;
  }

  buttonsConfigDefault: ButtonsConfig = {
    visible: true,
    strategy: ButtonsStrategy.DEFAULT
  };
  buttonsConfigSimple: ButtonsConfig = {
    visible: true,
    strategy: ButtonsStrategy.SIMPLE
  };
  buttonsConfigAdvanced: ButtonsConfig = {
    visible: true,
    strategy: ButtonsStrategy.ADVANCED
  };
  buttonsConfigFull: ButtonsConfig = {
    visible: true,
    strategy: ButtonsStrategy.FULL
  };
  buttonsConfigCustom: ButtonsConfig = {
    visible: true,
    strategy: ButtonsStrategy.CUSTOM,
    buttons: [
      KS_DEFAULT_BTN_FULL_SCREEN,
      KS_DEFAULT_BTN_EXTURL,
      KS_DEFAULT_BTN_DOWNLOAD,
      KS_DEFAULT_BTN_CLOSE
    ]
  };


  private getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
    return image ? images.indexOf(image) : -1;
  };
  onButtonAfterHook(event: ButtonEvent) {
    if (!event || !event.button) {
      return;
    }
  }

  onCustomButtonBeforeHook(event: ButtonEvent, galleryId: number | undefined) {
    if (!event || !event.button) {
      return;
    }

    if (event.button.type === ButtonType.CUSTOM) {
      this.addRandomImage();

      setTimeout(() => {
        // this.galleryService.openGallery(galleryId, this.images.length - 1);
      }, 0);
    }
  }

  onCustomButtonAfterHook(event: ButtonEvent, galleryId: number | undefined) {
    if (!event || !event.button) {
      return;
    }
  }

  addRandomImage() {
    const imageToCopy: Image = this.images[Math.floor(Math.random() * this.images.length)];
    const newImage: Image = new Image(this.images.length - 1 + 1, imageToCopy.modal, imageToCopy.plain);
    this.images = [...this.images, newImage];
  }

  //FileUpload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

  formatearEstadoCliente(estadoCliente: string): string {
    // Convertir a minúsculas y eliminar espacios en blanco
    return estadoCliente.toLowerCase().replace(/\s/g, '');
  }
  

  ingreso = '0';

  async ngOnInit() {

    this.ingreso = '1';

    window.scrollTo({ top: 0, behavior: 'smooth' })
    console.log('adapter :', adapter)
    console.log('adapter.browserDetails.browser', adapter.browserDetails.browser)

    // this.router.navigate(['/myUrlPath', "someId", "another ID"]);


    this.routeSub = this.route.params.subscribe(parametros => {
      this.caso_id = parametros.caso_id
      this.tienda_id = parametros.tienda_id
    })

    await this.getLeadData(this.caso_id)
  
    this.lead_to_update.id = this.lead.id
    this.lead_to_update.respondio_llamada = this.lead.respondio_llamada
    this.lead_to_update.envio_cotizacion = this.lead.envio_cotizacion
    this.lead_to_update.respuesta_generica = this.lead.respuesta_generica ? parseInt(this.lead.respuesta_generica) : -1
    this.lead_to_update.comentarios = this.lead.comentarios
    this.lead_to_update.estado_caso = this.lead.estado_caso
    this.lead_to_update.call_again = this.lead.call_again
    


    // var response = await <any>lastValueFrom(this.http.get('https://snow-lark-6415.twil.io/capability-token'))
    let retries = 0;
    const maxRetries = 5;
    var response = null
    while (retries < maxRetries) {
      try {

        response = await <any>lastValueFrom(this.http.get(`${environment.url_api}/MakeCall/generate_token`));

        // Tu código para manejar la respuesta exitosa aquí
        break; // salir del ciclo si la solicitud es exitosa
      } catch (error) {
        // Tu código para manejar el error aquí
        console.error(error);
        retries++; // incrementar el contador de reintentos
        await new Promise(resolve => setTimeout(resolve, 1000)); // esperar 1 segundo antes de volver a intentar
      }
    }

    if (retries >= maxRetries) {
      console.error('Número máximo de reintentos alcanzado');
    }

    console.log('Se realizó intento : ', retries)

  }


 modal_reprogramado_funcion() {

      const dialogRef = this.modalService.open(ModalReprogramadoLeadComponent, {
        size: 'xl'
      })
  
      dialogRef.componentInstance.caso_id = this.caso_id
      dialogRef.componentInstance.jwt = this.jwt
  
      dialogRef.result.then((data) => {
      
      })


    
  }


  handleEstadoClienteChange(selectedOption: string) {

    if (selectedOption === 'Reprogramado') {

      const dialogRef = this.modalService.open(ModalReprogramadoLeadComponent, {
        size: 'lg'
      })
  
      console.log('this.caso_id', this.caso_id)
  
      dialogRef.componentInstance.caso_id = this.caso_id
      dialogRef.componentInstance.jwt = this.jwt
  
      dialogRef.result.then((data) => {
        console.log(data)
      })
    }
  }


  async check_if_contesto(call_id) {
    const data = new FormData()
    data.append('call_id', call_id)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}MakeCall/get_recorded_call`, data))
    return response
  }

  caso_vinculado;

  abierto_caso_vinculado: boolean = false;

  toggleAccordion() {
    this.abierto_caso_vinculado = !this.abierto_caso_vinculado;
  }

  getSeguimientoCaso() {
    return this.lead.seguimiento_caso ? JSON.parse(this.lead.seguimiento_caso) : [];
  }

  getSupervisoresTienda() {
    return this.lead.supervisor_tienda ? JSON.parse(this.lead.supervisor_tienda) : [];
  }

  getSellerEmails() {
    return this.lead.seller ? JSON.parse(this.lead.seller) : [];
  }

  abrir_historial = false; 
  toggleHistorial() {
    this.abrir_historial = !this.abrir_historial;
  }


  historial = [];

  async getLeadData(caso_id) {
    const data = new FormData()

    data.append('ingreso', this.ingreso)
    data.append('caso_id', caso_id)
    data.append('jwt', this.jwt)
    data.append('see_detail', 'true')

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ContactCenterAPI/get_lead_detalle_by_id`, data))

    
    if (response.success) {

      this.lead = response.lead
      this.telefono = response.lead.celular
      this.comentarios_lead = response.comentarios;
      this.historial = response.historial;

      
      this.tiempos.primera_atencion = this.secondsToDhms(response.lead.diferencia_horas)
      this.tiempos.ultimo_click = response.lead.last_click
      this.tiempos.primera_apertura = this.secondsToDhms(response.lead.diferencia_horas_apertura)
      this.tiempos.last_edit = response.lead.first_apertura
      this.payloadForm = response.payload
      this.caso_vinculado = response.caso_vinculado

      this.ingreso = '0';


    } else {
      // 
      console.log('redireccionar')
      this.router.navigate([`contact-center/tienda/${this.tienda_id}`])
    } 
  }





  /* AGREGADO */

  isCollapsed: boolean = false;

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  async guardarNota() {

    if(this.nueva_nota){

      const data = new FormData()
      
      data.append('jwt', this.jwt)
      data.append('tienda_id', this.tienda_id)
      data.append('caso_id', this.caso_id)
      data.append('nota', this.nueva_nota)
      data.append('lead', JSON.stringify(this.lead_to_update))
      data.append('nota', this.nueva_nota)
  
      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}contactCenterAPI/new_comment_caso`, data))
  
      if(response.success){
        
        this.nueva_nota = "";
  
        const lead_response = <any>await lastValueFrom(this.http.post(`${environment.url_api}contactCenterAPI/get_lead_detalle_by_id`, data))
  
        this.comentarios_lead = <any>lead_response.comentarios
  
      }

      await this.getLeadData(this.caso_id);

    }



  }




  async actualizar() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('lead', JSON.stringify(this.lead_to_update))

    Swal.showLoading()
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}contactCenterAPI/update_lead`, data))

    if (response.success) {
      Swal.fire(response.message, '', 'success');

      await this.getLeadData(this.caso_id);

      
    } else {
      Swal.fire(response.message, '', 'error')
    }

  }
  see_history() {
    console.log('see_history')
    const dialogRef = this.modalService.open(LeadHistoryInfoComponent, {
      size: 'lg'
    })
    dialogRef.componentInstance.caso_id = this.caso_id
    dialogRef.componentInstance.jwt = this.jwt

    dialogRef.result.then((data) => {
      console.log(data)
    })
  }

  send_cotizacion() {
    console.log('send_cotizacion')
    const dialogRef = this.modalService.open(SendCotizacionComponent,
      {
        size: 'lg'
      })
    dialogRef.componentInstance.caso_id = this.caso_id
    dialogRef.componentInstance.jwt = this.jwt
    dialogRef.componentInstance.tienda_id = this.lead.concesionario_id

    dialogRef.result.then((data) => {
      if (data.success) {
        this.lead_to_update.envio_cotizacion = 'Si'
      }
    })
  }

  go_back() {
    this.location.back()
  }

  reasignar() {
    console.log("Reasignar el caso_id : " + this.caso_id)
    const dialogRef = this.modalService.open(ModalReasignarLeadComponent,
      {
        size: 'lg'
      })
    dialogRef.componentInstance.caso_id = this.caso_id
    dialogRef.componentInstance.jwt = this.jwt

    dialogRef.result.then((data) => {
      if (data.success) {
        this.lead = data.lead
        this.payloadForm = data.payload

        if (this.lead.utms) {
          this.lead.utms = JSON.parse(this.lead.utms)
        }
      }
    })
  }
  info() {
    console.log("Mostrar información del lead: " + this.caso_id)
  }

  ver_historial() {

    const dialogRef = this.modalService.open(ModalSeeUtmsHistoryComponent, {
      size: 'lg'
    })

    console.log('this.caso_id', this.caso_id)

    dialogRef.componentInstance.caso_id = this.caso_id
    dialogRef.componentInstance.jwt = this.jwt

    dialogRef.result.then((data) => {
      console.log(data)
    })
  }

  secondsToDhms = (seconds) => {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24))
    var h = Math.floor(seconds % (3600 * 24) / 3600)
    var m = Math.floor(seconds % 3600 / 60)
    var s = Math.floor(seconds % 60)

    var dDisplay = d > 0 ? d + (d == 1 ? " dí­a, " : " días, ") : ""
    var hDisplay = h > 0 ? h + (h == 1 ? " hora, " : " horas, ") : ""
    var mDisplay = m > 0 ? m + (m == 1 ? " minuto, " : " minutos, ") : ""
    var sDisplay = s > 0 ? s + (s == 1 ? " segundo" : " segundos") : ""
    return dDisplay + hDisplay + mDisplay + sDisplay;
  }

  async programar_llamada() {
    const dialogRef = this.modalService.open(ModalProgramarLlamadaComponent,
      {
        size: 'xl'
      })
    dialogRef.componentInstance.caso_id = this.caso_id
    dialogRef.componentInstance.jwt = this.jwt
    dialogRef.componentInstance.tienda_id = this.lead.concesionario_id
    dialogRef.result.then((data) => {
      if (data.success) {
        const caso_id__selected = data.caso_id
        // this.redireccionarConNuevoId(caso_id__selected)
      }
    })
  }



}

